import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Switch, Route, Link} from 'react-router-dom'
import history from '../../history'

import DashboardOperator from './DashboardOperator'
import VideoList from './VideoList'
import VideoDetail from './VideoDetail'
import VideoNew from './VideoNew'
import ChannelList from './ChannelList'
import ChangePassword from './ChangePassword'
import ChannelNew from './ChannelNew'
import ChannelDetail from './ChannelDetail'
import VideoUploadPage from './VideoUploadPage'
import AudioUploadPage from './AudioUploadPage'
import LiveList from './LiveList'
import BillList from './BillList'

import {clearVideo} from '../../actions/video'
import LiveDetail from './LiveDetail'
import BillDetail from './BillDetail'
import Monitor from './Monitor'

export class OperatorRoutes extends Component {
  render () {
    return (
      <Switch>
        <Route exact path='/contents/new/' component={VideoNew} />
        <Route exact path='/contents/:id/*/' component={VideoDetail} />
        <Route exact path='/contents/:id/' component={VideoDetail} />
        <Route exact path='/contents/' component={VideoList} />

        <Route exact path='/live-videos/:id/*' component={LiveDetail} />
        <Route exact path='/live-videos/:id' component={LiveDetail} />
        <Route exact path='/live-videos/' component={LiveList} />

        <Route exact path='/channels/new/' component={ChannelNew} />
        <Route exact path='/channels/:id/*/' component={ChannelDetail} />
        <Route exact path='/channels/:id/' component={ChannelDetail} />
        <Route exact path='/channels/' component={ChannelList} />

        <Route exact path='/bills/:id/' component={BillDetail} />
        <Route exact path='/bills/' component={BillList} />

        <Route exact path='/drop/:channel_id/' component={VideoUploadPage} />
        <Route exact path='/drop/' component={VideoUploadPage} />

        <Route exact path='/drop-audio/:channel_id/' render={(props) => <AudioUploadPage isAudio={true} {...props}/>} />
        <Route exact path='/drop-audio/' render={(props) => <AudioUploadPage isAudio={true} {...props}/>} />
        <Route exact path='/monitor/' component={Monitor} />

        <Route exact path='/change-password/' component={ChangePassword}/>
        <Route exact path='/' component={DashboardOperator}/>
        <Route path='*' render={() => <div>{history.push('/')}</div>} />
      </Switch>
    )
  }
}

class OperatorMenu extends Component {
  renderChannels () {
    const {channels} = this.props
    if (channels) {
      let links = channels.results.map((channel) => {
        let url = `/channels/${channel.id}/contents`
        return (
          <li key={channel.id}>
            <Link className='btn channels' to={url}>
              <i className='fas fa-fw fa-angle-right'/>
              {channel.name}
            </Link>
          </li>
        )
      })

      return links
    }
  }

  renderMonitor () {
    if (this.props.monitorEnabled) {
      return (
        <li><Link className='btn' to='/monitor/'><i className='fas fa-fw fa-chart-network'/> Monitoreo (Beta)</Link></li>
      )
    }
  }

  render () {
    return (
      <React.Fragment>
        <li className='new-video'><Link className='btn' to='/drop/' onClick={this.props.clearVideo}><i className='fas fa-fw fa-plus'/> Nuevo Video</Link></li>
        <li className='new-audio'><Link className='btn' to='/drop-audio/' onClick={this.props.clearVideo}><i className='fas fa-fw fa-plus'/> Nuevo Audio</Link></li>
        <li><Link className='btn' to='/'><i className='fas fa-fw fa-home'/> Dashboard</Link></li>
        <li><Link className='btn' to='/contents/'><i className='fas fa-fw fa-film'/> Contenidos</Link></li>
        <li><Link className='btn' to='/live-videos/'><i className='fas fa-fw fa-video'/> Videos en Vivo</Link></li>
        <li><Link className='btn' to='/channels/'><i className='fas fa-fw fa-tv'/> Canales</Link></li>
        {this.renderChannels()}
        <li><Link className='btn' to='/bills/'><i className='fas fa-fw fa-calculator'/> Reporte de Uso</Link></li>
        {this.renderMonitor()}
        <li><Link className='btn' to='/change-password/'><i className='fas fa-fw fa-cog'/> Cambiar contraseña</Link></li>
      </React.Fragment>
    )
  }
}

function mapStateToProps ({list, monitor}) {
  return {
    channels: list.channels,
    monitorEnabled: monitor.enabled
  }
}

export default connect(mapStateToProps, {clearVideo})(OperatorMenu)

import React, {Component} from 'react'
import Dashboard from '../../Dashboard'
import {clearDashboard} from '../../../actions/dashboard'
import {connect} from 'react-redux'

class LiveStatsDashboard extends Component {

  componentWillUnmount () {
    this.props.clearDashboard()
  }

  renderDashBoard () {
    let entries = [{
      key: 'num_loads',
      class: 'primary stats',
      title: 'Cargas del streaming',
      icon: 'fas fa-spinner',
      tooltip: 'Cantidad de veces que el player se ha cargado <br> sin importar si el usuario dio Play al live-video'
    }, {
      key: 'num_plays',
      class: 'danger stats',
      title: 'Visualizaciones',
      icon: 'fas fa-eye',
      tooltip: 'Cantidad de veces que se presionó <br> el botón play en el reproductor '
    }, {
      key: 'play_rate',
      class: 'success stats',
      title: 'Tasa de visualización',
      icon: 'fas fa-percent',
      tooltip: 'Cantidad de visualizaciones <br> contra cantidad de cargas'
    }, {
      key: 'unique_players',
      class: 'warning stats',
      title: 'Cargas Únicas',
      icon: 'fas fa-user',
      tooltip: 'Cantidad total de usuarios <br> distintos que cargaron el live-video'
    }, {
      key: 'total_time_watched',
      class: 'secondary stats',
      title: 'Tiempo total visto',
      icon: 'fas fa-clock',
      tooltip: 'Suma del tiempo que los usuarios <br> estuvieron reproduciendo el <br> live-video en formato d hh:mm:ss',
      valueType: 'time'
    }, {
      key: 'avg_time_watched',
      class: 'info stats',
      title: 'Tiempo promedio visto',
      icon: 'fas fa-clock',
      tooltip: 'Segundos en promedio que los <br> usuarios estuvieron reproduciendo el <br> live-video en formato d hh:mm:ss',
      valueType: 'time'
    }]

    return (
      <div className={this.props.styleClass}>
        <Dashboard
          data={this.props.data}
          entries={entries}
        />
      </div>
    )
  }

  render () {
    return (
        <>
            {this.renderDashBoard()}
        </>
    )
  }
}

export default connect(null, {clearDashboard})(LiveStatsDashboard)

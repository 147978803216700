import React from 'react'
import {Link} from 'react-router-dom'
import Stepper from 'react-stepper-horizontal'
import {humanizeDate} from '../../../utils/formatDate'

// VIDEO status
export const WAITING_FILE = 'waiting_file'
export const QUEUING_FAILED = 'queuing_failed'
export const QUEUED = 'queued'
export const PROCESSING = 'processing'
export const PROCESSING_FAILED = 'processing_failed'
export const FINISHED = 'finished'
export const FAILED = 'failed'
export const NOT_FINISHED = 'not_finished'

// LIVE VIDEO status
export const ON = 'on'
export const OFF = 'off'
export const STARTING = 'starting'
export const STOPPING = 'stopping'

// LIVE VIDEO input status
export const HEALTHY = 'healthy'
export const UNHEALTHY = 'unhealthy'

// LIVE VIDEO CUT status
export const SCHEDULED = 'scheduled'
export const EXECUTING = 'executing'
export const PERFORMED = 'performed'

export function setActiveStep (state) {
  switch (state) {
    case WAITING_FILE:
      return 0
    case QUEUING_FAILED:
      return 1
    case QUEUED:
      return 1
    case PROCESSING:
      return 2
    case PROCESSING_FAILED:
      return 2
    case FINISHED:
      return 3
    default:
      return 0
  }
}

function renderJobPercentComplete (state, jobPercentComplete) {
  let text = 'Procesando'

  if (state === PROCESSING) {
    text = jobPercentComplete ? `${text} ${jobPercentComplete}%` : `${text} 0%`
  }

  return (
    <div>{text}</div>
  )
}

export function renderStepper (video) {
  if (video == null || video === undefined) {
    return (
      <div></div>
    )
  }

  return (
    <div>
      <Stepper steps={[
        {title: 'Subido'},
        {title: 'En Cola'},
        {title: renderJobPercentComplete(video.state, video.job_percent_complete)},
        {title: 'Listo'}
      ]}
      activeStep={ setActiveStep(video.state) }
      defaultColor='#b9b9b9'
      defaultBarColor='#b9b9b9'
      completeColor='#5A937D'
      activeColor={setStateNameColor(video.state)} />
    </div>
  )
}

export function renderLiveStepper (video) {
  if (video == null || video === undefined) {
    return (
      <div></div>
    )
  }
  return (
    <div>
      <Stepper steps={[
        {title: video.state}
      ]}
      activeName={renderStateName(video.state)}
      activeColor={setStateNameColor(video.state)} />
    </div>
  )
}

export function setStateNameColor (state) {
  switch (state) {
    case STOPPING:
    case STARTING:
      return '#B57628'

    case QUEUING_FAILED:
    case PROCESSING_FAILED:
    case OFF:
    case EXECUTING:
    case UNHEALTHY:
      return '#c1526f'

    case QUEUED:
    case FINISHED:
    case ON:
    case PERFORMED:
    case HEALTHY:
      return '#5A937D'

    case WAITING_FILE:
    case PROCESSING:
    case SCHEDULED:
      return '#638DB0'

    default:
      return '#ffffff'
  }
}

export function renderCircle (state, text) {
  return (
    <>
      <i className='fas fa-circle mr-2' style={{color: setStateNameColor(state)}}/>
      {text}
    </>
  )
}

export function renderStateName (state) {
  switch (state) {
    case WAITING_FILE:
      return renderCircle(state, 'Subiendo Archivo')
    case QUEUING_FAILED:
      return renderCircle(state, 'Falló Encolar')
    case QUEUED:
      return renderCircle(state, 'En Cola')
    case PROCESSING:
      return renderCircle(state, 'Procesando')
    case PROCESSING_FAILED:
      return renderCircle(state, 'Falló Pocesando')
    case FINISHED:
      return renderCircle(state, 'Listo')
    case ON:
      return renderCircle(state, 'Encendido')
    case OFF:
      return renderCircle(state, 'Apagado')
    case STARTING:
      return renderCircle(state, 'Encendiendo')
    case STOPPING:
      return renderCircle(state, 'Apagando')
    case SCHEDULED:
      return renderCircle(state, 'Programado')
    case EXECUTING:
      return renderCircle(state, 'En proceso')
    case PERFORMED:
      return renderCircle(state, 'Finalizado')
    default:
      return 0
  }
}

export function renderInputState (state) {
  if (state.length > 0) {
    return renderCircle(UNHEALTHY, `${state.length} alertas`)
  } else {
    return renderCircle(HEALTHY, 'OK')
  }
}

export function renderCutState (cut, id) {
  if (cut != null) {
    return (
      <div>
        <Link className='alert-summary-primary' to={`/live-videos/${id}/cuts/${cut.id}`}>
          Motivo: {cut.description}, se restablece: {humanizeDate(cut.final_time)}
        </Link>
      </div>
    )
  }
}

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field, reduxForm, stopSubmit, formValueSelector} from 'redux-form'
import history from '../../../history'

import {fieldRequired, isUrlValid, intervalValueLength} from '../../Form/validators'

import {FormGroup, InputField, ObjectMultiselectField, CheckboxField, ObjectDropDownListField, DropDownListField} from '../../Form'

import debounce from 'lodash/debounce'

import {isoCountries, geoType} from '../../../utils/isoCountries'

import {fetchLiveVideo, updateLiveVideo} from '../../../actions/live'
import {fetchTags} from '../../../actions/tags'
import {fetchChannels} from '../../../actions/channel'


import './index.css'

const intervalValueLengthUrl = intervalValueLength(0, 1024)

const LIVE_FORM = 'live_form'

class LiveForm extends Component {
  constructor (props) {
    super(props)
    this.onSearchChange = debounce(this.onSearchChange.bind(this), 500)
  }

  onSearchChange (search) {
    this.props.fetchTags({search})
  }

  componentDidMount () {
    this.props.fetchTags()
    this.props.fetchChannels()
  }

  componentDidUpdate () {
    this.props.fetchChannels()
  }

  formatTags (tags) {
    let names = tags.map(function (item) {
      return item['name']
    })

    return names
  }

  render () {
    const {error, reset, pristine, submitting,
      handleSubmit, tags, channels, geolocationEnabled} = this.props

    return (
      <form className='form container-fluid top-buffer' onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-md-12 offset-xl-3 col-xl-6'>

            <FormGroup labelText='Nombre'>
              <Field
                name='name'
                component={InputField}
                validate={[fieldRequired]}
              />
            </FormGroup>

            <FormGroup labelText='Canal'>
              <Field
                name='channel'
                component={ObjectDropDownListField}
                filter='contains'
                data={channels ? channels.results : []}
                textField='name'
                valueField='id'
                validate={[fieldRequired]}
              />
            </FormGroup>

            <FormGroup labelText='Tags'>
              <Field
                name='tags'
                component={ObjectMultiselectField}
                filter='contains'
                data={tags ? tags.results : []}
                textField='name'
                valueField='name'
                allowCreate='onFilter'
                onSearch={this.onSearchChange.bind(this)}
              />
            </FormGroup>

            <FormGroup
              labelText='Url de publicidad'
              helpText='Ejemplo: https://www.publicidad.com'>
              <Field
                name='ads_vast_url'
                component={InputField}
                validate={[isUrlValid, intervalValueLengthUrl]}
              />
            </FormGroup>

            <FormGroup
              labelText='Habilitar publicidad?'
              helpText='Si se deshabilita, el video no mostrará publicidad de ningun tipo.'>
              <Field
                name='enable_ads'
                component={CheckboxField}
                className='ads_checkbox'
              />
            </FormGroup>

            <FormGroup
              labelText='Reproducción automática?'
              helpText='Si se habilita el video iniciará automaticamente. En algunos navegadores se iniciará la reproducción con el audio en mute'>
              <Field
                name='autoplay'
                data={['c', 'y', 'n']}
                textField={ (d) => {
                  const labels = {
                    'y': 'Si',
                    'n': 'No',
                    'c': 'Según la configuración del Canal'
                  }
                  return labels[d]
                }}
                component={DropDownListField}
              />
            </FormGroup>

            <FormGroup labelText='Tipo de restricción geográfica'>
              <Field
                name='geolocation_type'
                id='geolocation_type'
                component={ObjectDropDownListField}
                data={geoType}
                textField='name'
                valueField='id'
              />
            </FormGroup>

            {geolocationEnabled && <FormGroup labelText='Paises'>
              <Field
                name='geolocation_countries'
                component={ObjectMultiselectField}
                filter='contains'
                data={isoCountries}
                textField='name'
                valueField='id'
                onSearch={this.onSearchChange.bind(this)}
              />
            </FormGroup>}

            <FormGroup>
              <button className='btn btn-primary' type='submit'>Guardar</button>
              <button className='btn btn-outline-secodary' disabled={pristine || submitting} onClick={reset} >Limpiar</button>
            </FormGroup>
            {error && (
              <span className='error'>
                <i className='fas fa-fw fa-times' />
                {error}
              </span>
            )}
          </div>
        </div>
      </form>
    )
  }
}

function onSubmit (values, dispatch) {
  let data = {...values}
  if (data.id) {
    return dispatch(updateLiveVideo(data)).then(() => {
      history.push(`/live-videos/${data.id}/`)
    })
  }
}

function onSubmitFail (errors, dispatch, submitError) {
  if (submitError) {
    dispatch(stopSubmit(LIVE_FORM, {_error: submitError.response.data.non_field_errors[0]}))
  } else if (errors) {
    dispatch(stopSubmit(LIVE_FORM, {_error: 'Algunos campos contienen errores.'}))
  }
}

function asyncValidate (values, dispatch) {
  let data = {...values}
  if (data.id) {
    return dispatch(updateLiveVideo(data)).then(() => {
      dispatch(fetchLiveVideo(data.id))
      history.push(`/live-videos/${data.id}/`)
    })
  }
}

const LiveFormFormed = reduxForm({
  form: LIVE_FORM,
  enableReinitialize: true,
  onSubmit: onSubmit,
  onSubmitFail: onSubmitFail,
  asyncValidate,
  asyncBlurFields: ['name']
})(LiveForm)

const selector = formValueSelector(LIVE_FORM)

function mapStateToProps (state) {
  const geolocationType = selector(state, 'geolocation_type')
  return {
    geolocationEnabled: geolocationType !== 'none',
    tags: state.list.tags,
    channels: state.list.channels
  }
}

export default connect(mapStateToProps, {
  fetchTags,
  fetchChannels
})(LiveFormFormed)

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import {renderStateName, ON, OFF, STARTING, STOPPING, renderCutState, renderInputState} from '../utils/stepper'
import {toNumericDate} from '../../../utils/formatDate'
import {isoCountries, geoType} from '../../../utils/isoCountries'

import './index.css'
import {changeLiveStateToStarting, changeLiveStateToStopping, fetchLiveVideo} from '../../../actions/live'

class LiveSummary extends Component {
  componentWillUnmount () {
    clearInterval(this.timerId)
  }

  componentDidMount () {
    if (this.props.securityEnabled) {
      this.props.fetchIframe(this.props.video.video_id)
    }
  }

  componentWillReceiveProps (nextProps) {
    const actualProps = this.props
    if (actualProps.video !== nextProps.video) {
      this.props = nextProps
    }
  }

  renderTags () {
    const {tags} = this.props.video
    if (tags.length === 0) {
      return ('(Sin datos)')
    } else {
      let links = tags.map((tag) => {
        return <li key={tag.id} className='li'>
          <Link to={`/live-videos/?tags__id=${tag.id}`}>
            {tag.name}
          </Link>
        </li>
      })
      return links
    }
  }

  getEmbedUrl () {
    const {video} = this.props
    const {securityEnabled} = this.props
    const {security} = this.props
    if (security && securityEnabled) {
      return this.props.security.embed_url
    }

    if (!securityEnabled) {
      return `/player/embed/${video.video_id}/`
    }
  }

  getEmbedIframe () {
    const {video, securityEnabled, security} = this.props
    if (security && securityEnabled) {
      return this.props.security.embed_code.replaceAll(' ', '\n ')
    }

    // TODO: hay un bug con el autoplay de los ads, un hack para resolver esto
    // es no permiitr autoplay en el iframe. Esto es un hack rapido que soluciona
    // Un problema operativo de ElPais. A futuro solucionar esto dado que sacar el allow autoplay esta mal.
    let allowStr = 'autoplay;fullscreen'
    if (video.autoplay === 'n') {
      allowStr = 'fullscreen'
    }

    return `<iframe
    src='//${window.location.host}/player/embed/${video.video_id}/'
    width='560'
    height='315'
    frameBorder='0'
    scrolling='no'
    seamless='seamless'
    allow='${allowStr}'>
</iframe>`
  }

  renderChannel () {
    const {channel, id} = this.props.video

    if (!channel) {
      return (
        <Link to={`/live-videos/${id}/edit/`} className='alert-summary'>
          Agregar Canal
        </Link>
      )
    } else {
      return (
        <Link to={`/channels/${channel.id}`}>
          {channel.name}
        </Link>
      )
    }
  }

  renderRestrictions (video) {

    if (video.geolocation_type !== 'none') {
      return (
        <div>
          <div>
            Tipo: {geoType.find(dict => dict.id === video.geolocation_type).name}
          </div>
          <div>
            Países: {
              video.geolocation_countries.map((countryCode) => (
                isoCountries.find(dict => dict.id === countryCode).name
              )).join(', ')
            }
          </div>
        </div>
      )
    } else {
      return <div>(No hay restricción)</div>
    }
  }

  getLiveInputState (input_state) {
    return (
      <div>
        {renderInputState(input_state)}
        {input_state.length > 0 && <li>{input_state.join(', ')}</li>}
      </div>
    )
  }

  render () {
    const {video} = this.props
    const {session} = this.props

    const autoplayOptions = {
      'c': 'Según la configuración del Canal',
      'y': 'Si',
      'n': 'No'
    }

    return (
      <div className='row summary'>
        <div className='col-md-12 col-xl-6'>
          <div className='detail'>
            <ul className='properties'>
              <li>
                <div className='col-xl-12'>
                  <div className='property'><i className='fas fa-fw fa-signature'></i> Nombre</div>
                  {video.name}
                </div>
              </li>
              <li>
                <div className='col-xl-12'>
                  <div className='property'><i className='fas fa-fw fa-id-card'></i> Id</div>
                  {video.video_id}
                </div>
              </li>

              <li>
                <div className='col-xl-12'>
                  <div className='property'><i className='fas fa-fw fa-user-crown'></i> Creador</div>
                  {video.created_by.username}
                </div>
              </li>

              <li>
                <div className='col-xl-12'>
                  <div className='property'><i className='fas fa-fw fa-calendar'></i> Fecha de creación</div>
                  {toNumericDate(video.created_at)}
                </div>
              </li>

              <li>
                <div className='col-xl-12'>
                  <div className='property'><i className='fas fa-fw fa-tv'></i> Canal</div>
                  <div className='summary-inner-list'>
                    <ul>
                      {this.renderChannel()}
                    </ul>
                  </div>
                </div>
              </li>

              <li>
                <div className='col-xl-12'>
                  <div className='property'><i className='fas fa-fw fa-tags'></i> Tags</div>
                  <div className='summary-inner-list'>
                    <ul>
                      {this.renderTags()}
                    </ul>
                  </div>
                </div>
              </li>

              <li>
                <div className='col-xl-12'>
                  <div className='property'><i className='fas fa-fw fa-signal-stream'></i> Estado</div>
                  <div className='summary-inner-list'>
                    <ul>
                      {renderStateName(video.state)}
                      {renderCutState(video.actual_cut, video.id)}
                    </ul>
                  </div>
                </div>
              </li>

              <li>
                <div className='col-xl-12'>
                  <div className='btn-group' role='group'>
                    {(video.state === OFF || video.state === STOPPING) && <button
                      type='button' className='btn btn-primary' onClick={() => this.props.changeLiveStateToStarting(video.id, session)}
                    ><i className='fas fa-play'></i> Encender</button>}
                    {(video.state === ON || video.state === STARTING) && <button
                      type='button' className='btn btn-primary' onClick={() => this.props.changeLiveStateToStopping(video.id, session)}
                    ><i className='fas fa-stop'></i> Apagar</button>}
                  </div>
                </div>
              </li>

              {video.state !== OFF && <li>
                <div className='col-xl-12'>
                  <div className='property'><i className='fas fa-fw fa-stethoscope'></i> Estado del origen</div>
                  <div className='summary-inner-list'>
                    <ul>
                      {this.getLiveInputState(video.input_state)}
                    </ul>
                  </div>
                </div>
              </li>}

              <li>
                <div className='col-xl-12'>
                  <div className='property'><i className='fas fa-fw fa-video'></i> URL de Transmisión</div>
                  {video.ml_input_url || '(Sin datos)'}
                </div>
              </li>

              <li>
                <div className='col-xl-12'>
                  <div className='property'><i className='fas fa-fw fa-ad'></i> URL de Publicidad</div>
                  <div className='summary-inner-list'>
                    <ul style={{'wordWrap': 'break-word'}}>
                      {video.ads_vast_url || '(Sin datos)'}
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div className='col-xl-12'>
                  <div className='property'><i className='fas fa-fw fa-play-circle'></i> Reproducción Automática</div>
                  <div className='summary-inner-list'>
                    <ul style={{'wordWrap': 'break-word'}}>
                      {autoplayOptions[video.autoplay]}
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div className='col-xl-12'>
                  <div className='property'><i className='fas fa-fw fa-globe-americas'></i> Restricción geográfica</div>
                  {this.renderRestrictions(video)}
                </div>
              </li>

            </ul>
          </div>
        </div>
        {(video.channel) &&
          <div className='col-md-12 col-xl-6'>
            <div id='iframe-info-detail' className='alert alert-info highlight'>
              <div className='iframe'>
                <iframe
                  className='resp-iframe'
                  title='player'
                  src={this.getEmbedUrl()}
                  width='720'
                  height='405'
                  scrolling='no'
                  seamless='seamless'
                  allow='autoplay; fullscreen'/>
              </div>
              <div className='iframe-code'>
                <hr />
                <h5>Inserte el live video en su página utilizando el siguiente código:</h5>
                <pre><code>
                  {this.getEmbedIframe()}
                </code>
                </pre>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps ({live, session, tracking, security}) {
  return {
    video: live,
    user: session.user,
    session,
    tracking,
    security
  }
}

export default connect(mapStateToProps, {
  changeLiveStateToStarting,
  changeLiveStateToStopping,
  fetchLiveVideo
})(LiveSummary)

import React, {Component} from 'react'
import Dashboard from '../../Dashboard'
import {clearDashboard} from '../../../actions/dashboard'
import {connect} from 'react-redux'

class VideoStatsDashboard extends Component {

  componentWillUnmount () {
    this.props.clearDashboard()
  }

  renderDashBoard () {
    let {showSpecificVideoInfo} = this.props

    let entries = [{
      key: 'num_loads',
      class: 'primary stats',
      title: 'Cargas de reproductor',
      icon: 'fas fa-spinner',
      tooltip: 'Cantidad de veces que el player se ha cargado <br> sin importar si el usuario dio Play'
    }, {
      key: 'num_plays',
      class: 'danger stats',
      title: 'Reproducciones',
      icon: 'fas fa-eye',
      tooltip: 'Cantidad de veces que se presionó <br> el botón play en el reproductor'
    }, {
      key: 'play_rate',
      class: 'success stats',
      title: 'Tasa de reproducción',
      icon: 'fas fa-percent',
      tooltip: 'Cantidad de reproducciones <br> contra cantidad de cargas'
    }, {
      key: 'unique_players',
      class: 'warning stats',
      title: 'Cargas Únicas',
      icon: 'fas fa-user',
      tooltip: 'Cantidad total de usuarios <br> distintos que cargaron el contenido'
    }, {
      key: 'rewatchers',
      class: 'secondary stats',
      title: 'Rewatchers',
      icon: 'fas fa-repeat',
      tooltip: 'Cantidad de reproducciones que tuvieron una  <br>  duracion mayor a la duracion total del contenido'
    }, {
      key: 'skippers',
      class: 'warning stats',
      title: 'Skippers',
      icon: 'fas fa-forward',
      tooltip: 'Cantidad de reproducciones que adelantaron <br> en algun punto el contenido'
    }, {
      key: 'total_time_watched',
      class: 'success stats',
      title: 'Tiempo total visto',
      icon: 'fas fa-clock',
      tooltip: 'Suma del tiempo que los usuarios <br> estuvieron reproduciendo <br> contenido en formato d hh:mm:ss',
      valueType: 'time'
    }, {
      key: 'avg_time_watched',
      class: 'info stats',
      title: 'Tiempo promedio visto',
      icon: 'fas fa-clock',
      tooltip: 'Segundos en promedio que los <br> usuarios estuvieron reproduciendo <br> contenido en formato d hh:mm:ss',
      valueType: 'time'
    }]

    if (showSpecificVideoInfo) {
      entries.push({
        key: 'avg_time_reached',
        class: 'primary stats',
        title: 'Promedio alcanzado',
        icon: 'fas fa-percent',
        tooltip: 'Promedio del máximo tiempo que alcanzaron <br> los usuarios',
        valueType: 'percent',
        render: (value) => {
          let {data} = this.props
          if (data) {
            return (data.duration ? (value / data.duration * 100).toFixed(2) : '0.00')
          }
          return '...'
        }
      }, {
        key: 'max_time',
        class: 'danger stats',
        title: 'Tiempo maximo alcanzado',
        icon: 'fas fa-clock',
        tooltip: 'Tiempo máximo alcanzado por algún usuario',
        valueType: 'time'
      })
    }

    return (
      <div className={this.props.styleClass}>
        <Dashboard
          data={this.props.data}
          entries={entries}
        />
      </div>
    )
  }

  render () {
    return (
        <>
            {this.renderDashBoard()}
        </>
    )
  }
}

export default connect(null, {clearDashboard})(VideoStatsDashboard)
